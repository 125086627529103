<template>

  <v-card>

    <v-card-title>
      Nile Breweries
    </v-card-title>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <Row title="Credit Application" >
              <v-btn color="primary" small dense :to="{name: 'NileCreditApplicationView', query: { uuid: customer.uuid }}" class="text-left">
                <v-icon dense>mdi-form-select</v-icon>
                <span>Application</span>
              </v-btn>
            </Row>
            <Row title="Do Not Lend?" >
              <v-switch
                v-model="customer.doNotLend"
                :label="doNotLendLabel"
              ></v-switch>
            </Row>
            <Row title="Field Agent">
              <v-select :items="fieldAgents" label="Agronomist" v-model="fieldAgentId"></v-select>
            </Row>
            <Row title="FCS Card Number">
              <code v-if="fcsCardNumber">{{fcsCardNumber}}</code>
              <v-text-field v-model="newFcsCardNumber" :counter="14" label="FCS Card Number" v-else >
                <v-icon slot="append-outer" @click="saveFcs" :color="(fcsValid ? 'primary' : 'grey lighten-2')">mdi-check</v-icon>
              </v-text-field>
            </Row>
            <Row title="Credit Available">
              <ServerSideResult :test="creditAvailable">
                <Currency :value="creditAvailable" />
              </ServerSideResult>
            </Row>
            <Row title="Credit Available: No FCS" v-if="!fcsCardNumber">
              <ServerSideResult :test="creditAvailableNoFcs">
                <Currency :value="creditAvailableNoFcs" />
              </ServerSideResult>
            </Row>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

  </v-card>

</template>

<script>
  import lookups from '@/mixins/lookups';

  import Row from '@/components/Row';
  import ServerSideResult from '@/components/ServerSideResult';
  import Currency from '@/components/Currency';

  export default {
    components: { Row, ServerSideResult, Currency },

    props: ['customer'],

    data: function () {
      return {
        creditAvailable: undefined,
        creditAvailableNoFcs: undefined,
        fcsCardNumber: undefined,
        newFcsCardNumber: undefined,
        fieldAgentId: undefined,
        fieldAgents: [
          { text: 'Caroline Yesho', value: 3020398 },
          { text: 'Claire Cherop', value: 3020399 },
          { text: 'Collins Musau', value: 3020400 },
          { text: 'Ivan Ndiwa', value: 3020401 },
          { text: 'Kennedy Mwesigwa', value: 3025316 },
          { text: 'Nelson Musisi', value: 3025315 },
          { text: 'Omara Henry', value: 3025317 },
        ],
      };
    },

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      totalFilledHugoFormFields() {
        return Object.values(this.$store.getters.hugoFormFields.sheet5Phase1Section1).filter(form => !!form).length;
      },
      totalFilledHugoFormFieldsSheet5Phase2() {
        return Object.values(this.$store.getters.hugoFormFields.sheet5Phase1Section2).filter(form => !!form).length;
      },
      show() {
        let r = false;
        if (this.partnerCode==='nile_ug') {
          r = true;
        }
        return r;
      },
      lend() { return this.customer.doNotLend; },
      doNotLendLabel() {
        let r = 'Lending Okay';
        if (this.lend) {
          r = 'Don\'t lend';
        }
        return r;
      },
      fcsValid() { return (this.newFcsCardNumber || '').replace(/\s/g,'').length>=10; },
    },

    watch: {
      lend: function(to) {
        this.$store.dispatch('upsertCustomer', this.customer);
      },
      fieldAgentId: function(to) {
        const name = (this.fieldAgents.find(e => e.value===to) || {}).text;
        this.customer.fieldAgent = {
          id: to,
          name: name,
        };
        this.customer.depot.id = {
          3025316: 320,
          3020399: 308,
          3020400: 309,
          3020401: 309,
          3020398: 312,
          3025315: 311,
          3025317: 321,
        }[to];
        this.$store.dispatch('upsertCustomer', this.customer);
      },
    },

    methods: {
      saveFcs() {
        if (this.fcsValid) {
          this.fcsCardNumber = this.newFcsCardNumber;
          this.customer.demographic = this.customer.demographic || {};
          this.customer.demographic.identityNumbers = this.customer.demographic.identityNumbers || {};
          this.customer.demographic.identityNumbers['FCS Card Number'] = this.newFcsCardNumber;
          this.$store.dispatch('upsertCustomer', this.customer);
        }
      }
    },

    mounted() {
      this.fieldAgentId = null;
      if (this.customer.fieldAgent) {
        this.fieldAgentId = this.customer.fieldAgent.id;
      }
      if (this.customer.demographic && this.customer.demographic.identityNumbers) {
        this.fcsCardNumber = this.customer.demographic.identityNumbers['FCS Card Number']
      }
      this.remoteLookup('nileUgCreditAvailable', { uuid: this.customer.uuid }).then( creditAvailable => this.creditAvailable = creditAvailable ).catch(e => this.creditAvailable = null);
      this.remoteLookup('nileUgCreditAvailableNoFcs', { uuid: this.customer.uuid }).then( creditAvailableNoFcs => this.creditAvailableNoFcs = creditAvailableNoFcs ).catch(e => this.creditAvailableNoFcs = null);
    },

    mixins: [lookups],

  }
</script>
