<template>
    <v-container>
      <v-row>
        <v-col col="6" class="mr-4">
          <Heading title="Customer Balances" />
          <v-simple-table v-if="balances" >
            <template v-slot:default>
              <tbody>
                <Row title="Balance">
                  <ServerSideResult :test="balances">
                    <Currency :value="balances.total" />
                  </ServerSideResult>
                </Row>
                <Row title="Interest">
                  <ServerSideResult :test="balances">
                    <Currency :value="balances.interest" />
                  </ServerSideResult>
                </Row>
                <Row title="Insurance">
                  <ServerSideResult :test="balances">
                    <Currency :value="balances.insurance" />
                  </ServerSideResult>
                </Row>
                <Row title="Deliveries">
                  <ServerSideResult :test="balances">
                    <Currency :value="balances.inputs" />
                  </ServerSideResult>
                </Row>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col col="6" class="mx-2">
          <v-form v-model="formValid">
            <PageHeader :title="$t('title')"  v-if="customer" save @back="toCustomer" @save="save" :valid='formValid' >
        
              <v-row >
        
                <v-col cols="12" sm="4">
                  <v-select :items="crops" :label="$t('crop')" class="text-left" v-model="cropId" :rules="[cropRules]"></v-select>
                </v-col>
        
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="quantity"
                    :rules="[quantityRules]"
                    :label="$t('quantity')"
                    type='number'
                    suffix='Kg'
                    min="0"
                    required
                  ></v-text-field>
                </v-col>
        
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="unitPrice"
                    :rules="[priceRules]"
                    :label="$t('Price per Kg')"
                    type='number'
                    suffix='UGX'
                    min="0"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="amount"
                    :rules="[priceRules]"
                    :label="$t('Total Amount')"
                    type='number'
                    suffix='UGX'
                    min="0"
                    required
                    readonly
                  ></v-text-field>
                </v-col>
      
        
              </v-row>
        
        
            </PageHeader>
          </v-form>
        </v-col>
      </v-row>
        
          <v-row class="flex-column">
            <v-col>
              <v-row align="start" class="mt-4">
                <span class="text-h5 hidden-sm-and-down">
                  Pay Loan
                </span>
            </v-row>
              
            </v-col>
            <v-col>
            <v-row no-gutters >
                <v-col cols="12">
                    <v-form>
                        <v-text-field
                            v-model="paymentAmount"
                            :rules="[loanPaymentRules]"
                            :label="$t('amount')"
                            type='number'
                            :suffix="currency"
                            min="0"
                            required
                        ></v-text-field>
                    </v-form>
                </v-col>
                </v-row>
            </v-col>
          </v-row>
    </v-container>

  </template>
  
  
  <script>
    import { v4 as uuidv4 } from 'uuid';
    import referenceNumbers from '@/mixins/referenceNumbers';
    import Heading from '@/components/Heading';
    import PageHeader from '@/components/PageHeader';
    import Currency from '@/components/Currency';

    import Row from '@/components/Row';
    import ServerSideResult from '@/components/ServerSideResult';
    import displaySwitches from '@/mixins/displaySwitches';
    import lookups from '@/mixins/lookups';
    import partnerCodeEnums from "@/mixins/partnerCodeEnums";
  
    export default {
  
      components: { PageHeader, Currency, Row, ServerSideResult, Heading },
  
      data: () => ({
        formValid: false,
  
        cropId: undefined,
        deliveryNumber: undefined,
        quantity: 0,
        price: 0,
        paymentAmount: 0,
        unitPrice: 0,
        balances: undefined
  
      }),
  
      computed: {
        partnerCode() { return this.$store.getters.settings.partnerCode; },
        customerUuid() { return this.$route.query.uuid; },
        customer() { return this.$store.getters.customer(this.customerUuid); },
        crops() { return this.$store.getters.crops; },
        cropLookups() { return this.$store.getters.cropLookups; },
        cropName() { return this.cropLookups[this.cropId]; },
        baseStroageCosts() { return (this.partnerCode==='apex_tz' ? 15 : 0); },
        storageCosts() { return this.baseStroageCosts*this.quantity; },
        location() { return ((this.$store.getters.geolocation || {}).coords); },
        fieldAgentId() { return this.$store.getters.settings.fieldAgentId; },
        amount() { return this.unitPrice * this.quantity},
        domain() {
          const hostname = window.location.hostname;
          let d = hostname.split('.')[0];
          return d;
        },
        showPrice() { return (this.domain==='warehouse'); },

        location() { return ((this.$store.getters.geolocation || {}).coords); },
        currency() {
            let currency = this.$store.getters.currency;
            const code =  this.$store.getters.settings.partnerCode;
            return code == "nile_ug" ? 'UGX': currency;
        },
      },
  
      methods: {
        cropRules(v) { return !!v || this.$t('required'); },
        quantityRules(v) {
          let r = true;
          if (parseFloat(v) <= 0) { r = this.$t('minZero'); }
          if (parseFloat(v) > 2000000) { r = this.$t('maxExceeded'); }
          if (v.length==0) { r = this.$t('required') }
          return r;
        },
        priceRules(v) {
          let r = true;
          if (parseFloat(v) <= 0) { r = this.$t('minZero'); }
          if (parseFloat(v) > 20000000) { r = this.$t('maxExceeded'); }
          if (v.length==0) { r = this.$t('required') }
          return r;
        },
        loanPaymentRules(v) {
          let r = true;
          if (parseFloat(v) > this.amount) {r = "Not enough Cash"}
          if (parseFloat(v) <= 0) { r = this.$t('minZero'); }
          if (parseFloat(v) > 20000000) { r = this.$t('maxExceeded'); }
          if (v.length==0) { r = this.$t('required') }
          return r;
        },

        toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
        save() {
  
          let delivery = {
  
            uuid: uuidv4(),
            createdAt: new Date().toISOString(),
            deliveryNumber: this.deliveryNumber,
            cropId: this.cropId,
            quantity: parseInt(this.quantity),
            storageCosts: this.storageCosts,
            cropName: this.cropName,
  
            customerUuid: this.customerUuid,
            location: this.location,
            capturedById: this.fieldAgentId,
          }
  
          if (this.partnerCode=='eni_ke') {
            delivery.price_per_unit = 675
          }
          
          this.$store.dispatch('addDelivery', delivery);
          this.saveReceipt()
  
          // this.$router.push({ name: 'ShowDelivery', query: { uuid: this.customerUuid, deliveryUuid: delivery.uuid } });
        },

        saveReceipt() {

          const receipt = {
          uuid: uuidv4(),
          createdAt: new Date().toISOString(),
          receiptNumber: this.receiptNumber,
          amount: parseFloat(this.paymentAmount),
          customerUuid: this.customerUuid,
          location: this.location,
          capturedById: this.fieldAgentId,
          }
          this.$store.dispatch('addReceipt', receipt);

          this.$router.push({ name: 'ShowReceipt', query: { uuid: this.customerUuid, receiptUuid: receipt.uuid } });
        },
      },

  
      mounted() {
        this.deliveryNumber = this.generateReferenceNumber()
        this.remoteLookup('nile_ug_loan_balances', {uuid: this.customer.uuid}).then( balances => {this.balances = balances} ).catch(console.log("something went wrong"))
      },

      mixins: [displaySwitches, partnerCodeEnums, lookups, referenceNumbers]
  
    }
  </script>
  
  
  <i18n>
  {
    "en": {
      "title": "Deliveries",
      "storageCosts": "Storage Costs",
      "minZero": "Quantity must be a positive number",
      "maxExceeded": "Quantity must be less than 2000000 kg",
      "price": "Required Price"
    },
    "es": {
      "title": "Entregas",
      "storageCosts": "Costos de almacenamiento",
      "minZero": "La cantidad debe ser un número positivo",
      "maxExceeded": "La cantidad debe ser inferior a 2000000 kg.",
      "price": "Precio requerido"
    },
    "sw": {
      "title": "Wanaojifungua",
      "storageCosts": "Gharama za Uhifadhi",
      "minZero": "Wingi lazima iwe nambari nzuri",
      "maxExceeded": "Wingi lazima uwe chini ya 2000000kg",
      "price": "Bei inayohitajika"
    }
  }
  </i18n>
  