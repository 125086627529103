import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/login/Home.vue'

import LocationDetail from '@/views/login/LocationDetail.vue'

import CreateFarmer from '@/views/farmers/Create.vue'
import EditFarmer from '@/views/farmers/Edit.vue'

import OnboardingInProgress from '@/views/partners/nucafe_ug/landing/OnboardingInProgress.vue'
import NucafeOrderInputs from '@/views/partners/nucafe_ug/landing/NucafeOrderInputs.vue'
import OrderInputs from '@/views/partners/nucafe_ug/landing/OrderInputs.vue'
import CoffeeDelivery from '@/views/partners/nucafe_ug/landing/CoffeeDelivery.vue'
import FinishOnboarding from '@/views/partners/nucafe_ug/landing/FinishOnboarding.vue'
import CreateContact from '@/views/contacts/Create.vue'
import CreateBusiness from '@/views/businesses/Create'
import CustomerSearch from '@/views/login/CustomerSearch'

import Customers from '@/views/customers/Index.vue'
import ShowCustomer from '@/views/customers/Show.vue'
import EditCustomer from '@/views/customers/Edit.vue'
import PrintCustomer from '@/views/customers/Print.vue'
import EditDemographics from '@/views/customers/EditDemographics.vue'
import EditCustomerLocation from '@/views/customers/EditLocation.vue'
import EditUzimaUgDetail from '@/views/customers/partners/uzima_ug/Edit.vue'
import VaOrder from '@/views/customers/partners/uzima_ug/VaOrder.vue'

import NewInputOrder from '@/views/inputOrders/New.vue'

import NewReceipt from '@/views/receipts/New.vue'
import ShowReceipt from '@/views/receipts/Show.vue'

import NewPurchase from '@/views/purchases/New.vue'
import ShowPurchase from '@/views/purchases/Show.vue'

import NewDelivery from '@/views/deliveries/New.vue'
import ShowDelivery from '@/views/deliveries/Show.vue'
import DeliveryDetails from '@/views/deliveries/DeliveryDetails.vue'

import CreateAction from '@/views/actions/Create.vue'

import NewCustomerAppointment from '@/views/customers/NewAppointment.vue'

import Field from '@/views/fields/Show.vue'
import FieldEdit from '@/views/fields/Edit.vue'
import FieldDelete from '@/views/fields/Delete.vue'
import FieldScore from '@/views/fields/components/Score';
import FieldMap from '@/views/fields/Map.vue'

import UzimaUgAgentScore from '@/views/partners/uzima_ug/ScoreAgent';

import VoucherRedemption from '@/views/vouchers/Redeem';

import SkuIndex from '@/views/skus/Index';

import Photos from '@/views/photos/Index.vue'
import Search from '@/views/search/Index.vue'

// import HRPhaseOne from '@/views/fields/partners/hr_co/PhaseOne.vue'

import RegisterTree from '@/views/trees/New.vue'
import Trees from '@/views/trees/Index.vue'
import TreeFinder from '@/views/trees/Search.vue'
import MilkCollection from "@/views/dairy/MilkCollection";

import ApprovalsIndex from '@/views/approvals/Index.vue'
import ApprovalShow from '@/views/approvals/Show.vue'
import ActionsIndex from '@/views/actions/Index.vue'

import ShowAssessment from "@/views/partners/hr_co/views/ShowAssessment";
import ShowTechnicalVisit from "@/views/partners/hr_co/views/ShowTechnicalVisit";
import ShowFieldContract from "@/views/partners/hr_co/views/ShowFieldContract";
import ShowFirstVisit from "@/views/partners/hr_co/views/ShowFirstVisit";
import NewFieldContract from "@/views/partners/hr_co/views/NewFieldContract";

import NileRegisterContact from '@/views/partners/nile_ug/NileStepperRegistrationForm'
import NileCreditApplicationView from '@/views/partners/nile_ug/NileCreditApplicationView'
import NileStepperLoanRequest from '@/views/partners/nile_ug/NileStepperLoanRequest'


import NuCafeUgRegisterContact from '@/views/partners/nucafe_ug/Create'

import UltimateTzLoanRequest from '@/views/partners/ultimate_tz/LoanRequest'
import UltimateTzSettlement from '@/views/partners/ultimate_tz/Settlement'
import UltimateTzRollover from '@/views/partners/ultimate_tz/Rollover'
import UltimateTzTopup from '@/views/partners/ultimate_tz/Topup'
import UltimateTzCashbox from '@/views/partners/ultimate_tz/Cashbox'
import UltimateTzSafebox from '@/views/partners/ultimate_tz/Safebox'
import UltimateTzCashboxSend from '@/views/partners/ultimate_tz/CashboxSend'
import UltimateTzExpense from '@/views/partners/ultimate_tz/Expense'
import UltimateTzInstrumentsNotDisbursed from '@/views/partners/ultimate_tz/InstrumentsNotDisbursed';
import UltimateTzScanner from '@/views/partners/ultimate_tz/UltimateTzScanner'

import Tests from '@/views/tests/Index'
import TestPusher from '@/views/tests/Pusher'
import TestValidation from "@/views/tests/Validation";
import TestFilesAndPhotos from "@/views/tests/TestFilesAndPhotos";
import TestIdentityNumbers from "@/views/tests/TestIdentityNumbers";
import TestFingerprints from "@/views/tests/TestFingerprints";
import TestFingerprintEnroll from "@/views/tests/TestFingerprintEnroll";
import TestIdentifyFingerprint from '@/views/tests/TestIdentifyFingerprint';
import TestUltimate from "@/views/tests/Ultimate";
import NewFirstVisit from "@/views/partners/hr_co/views/NewFirstVisit";
import TestUltimateTransactions from "@/views/tests/UltimateTransactions";
import TestSignature from "@/views/tests/TestSignature";
import TestDates from "@/views/tests/TestDates";

Vue.use(VueRouter)

const routes = [
  { path: '/',                          name: 'Home',                     component: Home                         },

  { path: '/locationDetail',            name: 'LocationDetail',           component: LocationDetail               },

  { path: '/farmers/new',               name: 'CreateFarmer',             component: CreateFarmer                 },
  { path: '/farmers/edit',              name: 'EditFarmer',               component: EditFarmer                   },

  { path: '/contacts/new',              name: 'CreateContact',            component: CreateContact                },
  { path: '/coffee_delivery',              name: 'CoffeeDelivery',            component: CoffeeDelivery           },
  { path: '/finish_onboarding',              name: 'FinishOnboarding',            component: FinishOnboarding     },
  { path: '/onboarding',              name: 'OnboardingInProgress',            component: OnboardingInProgress     },
  { path: '/add_order_inputs',              name: 'NucafeOrderInputs',            component: NucafeOrderInputs     },
  { path: '/order_inputs',              name: 'OrderInputs',            component: OrderInputs     },
  { path: '/customers',                 name: 'Customers',                component: Customers                    },
  { path: '/customer/search', name: 'SearchCustomer', component: CustomerSearch, props: true                      },
  { path: '/businesses/new',  name: 'CreateBusiness', component: CreateBusiness                                      },

  { path: '/customers/show',            name: 'ShowCustomer',             component: ShowCustomer                 },
  { path: '/customers/show/firstVisit', name: 'ViewFirstVisit',           component: ShowFirstVisit                 },
  { path: '/customers/edit',            name: 'EditCustomer',             component: EditCustomer                 },
  { path: '/customers/print',           name: 'PrintCustomer',            component: PrintCustomer                },
  { path: '/customers/demographics',    name: 'EditDemographics',         component: EditDemographics             },
  { path: '/customers/location',        name: 'EditCustomerLocation',     component: EditCustomerLocation         },
  { path: '/customers/uzima_ug',        name: 'EditUzimaUgDetail',        component: EditUzimaUgDetail            },
  { path: '/customer/uzima_ug/va/order', name: 'VaOrder',                 component: VaOrder                      },

  { path: '/customers/newAppointment',  name: 'NewCustomerAppointment',   component: NewCustomerAppointment       },


  { path: '/field',                     name: 'Field',                    component: Field }                      ,
  { path: '/field/assessment',          name: 'FieldAssessment',          component: ShowAssessment }                      ,
  { path: '/field/edit',                name: 'FieldEdit',                component: FieldEdit,                   },
  { path: '/field/delete',              name: 'FieldDelete',              component: FieldDelete,                 },
  { path: '/mapField',                  name: 'FieldMap',                 component: FieldMap                     },
  { path: '/field/score',               name: 'FieldScore',               component: FieldScore,                  },

  { path: '/inputOrders/new',           name: 'NewInputOrder',            component: NewInputOrder,               },

  { path: '/receipts/new',              name: 'NewReceipt',               component: NewReceipt,                  },
  { path: '/receipt',                   name: 'ShowReceipt',              component: ShowReceipt,                 },

  { path: '/purchases/new',             name: 'NewPurchase',              component: NewPurchase,                 },
  { path: '/purchase',                  name: 'ShowPurchase',             component: ShowPurchase,                },


  { path: '/deliveries/new',            name: 'NewDelivery',              component: NewDelivery,                 },
  { path: '/deliveries/DeliveryDetails',            name: 'DeliveryDetails',          component: DeliveryDetails,             },
  { path: '/delivery',                  name: 'ShowDelivery',             component: ShowDelivery,                },


  { path: '/actions/new',               name: 'CreateAction',             component: CreateAction                 },


  // { path: '/hr/score',                  name: 'HRFieldScore',             component: HRFieldScore,                },
  // { path: '/hr/contract',               name: 'HRContractDetail',         component: HRContractDetail,            },
  // { path: '/hr/phaseOne',               name: 'HRPhaseOne',               component: HRPhaseOne,                  }, //todo remove
  { path: '/hr/firstVisit',             name: 'HRFirstVisit',             component: NewFirstVisit,                },
  { path: '/hr/fieldContractRegister',  name: 'FieldContractRegister',    component: NewFieldContract        },
  { path: '/hr/fieldContractRegister/view',  name: 'ShowFieldContract',   component: ShowFieldContract       },

  { path: '/nile/register',    name: 'NileRegisterContact',       component: NileRegisterContact                  },
  { path: '/nile/application', name: 'NileCreditApplicationView', component: NileCreditApplicationView            },
  { path: '/nile/loan_application', name: 'NileStepperLoanRequest', component: NileStepperLoanRequest            },
  { path: '/nucafe/register',  name: 'NuCafeUgRegisterContact',       component: NuCafeUgRegisterContact          },

  { path: '/trees/new',                 name: 'RegisterTree',             component: RegisterTree,                },
  { path: '/trees',                     name: 'Trees',                    component: Trees,                       },
  { path: '/trees/search',              name: 'TreeFinder',               component: TreeFinder,                  },

  { path: '/approvals',                 name: 'ApprovalsIndex',               component: ApprovalsIndex,          },
  { path: '/approval' ,                 name: 'ApprovalShow',               component: ApprovalShow,          },
  { path: '/actions',                   name: 'ActionsIndex',                 component: ActionsIndex,            },

  { path: '/uzima_ug/asess_agent',      name: 'UzimaUgAgentScore',        component: UzimaUgAgentScore,           },

  { path: '/vouchers/redeem',           name: 'VoucherRedemption',        component: VoucherRedemption,           },
  { path: '/dairy/collect',             name: 'MilkCollection',           component: MilkCollection,           },


  { path: '/skus/index',                name: 'SkuIndex',                 component: SkuIndex,                    },


  { path: '/photos',                    name: 'Photos',                   component: Photos                       },
  { path: '/search',                    name: 'Search',                   component: Search                       },

  { path: '/technical-visit-view',      name: 'TechnicalVisitView',       component: ShowTechnicalVisit           },

  { path: '/ultimate_tz/loan_request',  name: 'UltimateTzLoanRequest',    component: UltimateTzLoanRequest       },
  { path: '/ultimate_tz/settlement',    name: 'UltimateTzSettlement',     component: UltimateTzSettlement        },
  { path: '/ultimate_tz/rollover',      name: 'UltimateTzRollover',       component: UltimateTzRollover          },
  { path: '/ultimate_tz/topup',         name: 'UltimateTzTopup',          component: UltimateTzTopup             },
  { path: '/ultimate_tz/cashbox',       name: 'UltimateTzCashbox',        component: UltimateTzCashbox           },
  { path: '/ultimate_tz/safebox',       name: 'UltimateTzSafebox',        component: UltimateTzSafebox           },
  { path: '/ultimate_tz/cashbox_send',  name: 'UltimateTzCashboxSend',    component: UltimateTzCashboxSend       },
  { path: '/ultimate_tz/expense',       name: 'UltimateTzExpense',        component: UltimateTzExpense           },
  { path: '/ultimate_tz/scanner',       name: 'UltimateTzScanner',        component: UltimateTzScanner           },
  { path: '/ultimate_tz/instruments_not_disbursed',  name: 'InstrumentsNotDisbursed',    component: UltimateTzInstrumentsNotDisbursed       },
  


  { path: '/about',                     name: 'About', // route level code-splitting // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },


  { path: '/tests',                     name: 'Tests',                          component: Tests                        },
  { path: '/tests/pusher',              name: 'TestPusher',                     component: TestPusher                   },
  { path: '/tests/validation',          name: 'TestValidation',                 component: TestValidation               },
  { path: '/tests/filesAndPhotos',      name: 'TestFilesAndPhotos',             component: TestFilesAndPhotos           },
  { path: '/tests/identityNumbers',     name: 'TestIdentityNumbers',            component: TestIdentityNumbers          },
  { path: '/tests/Fingerprints',        name: 'TestFingerprints',               component: TestFingerprints             },
  { path: '/tests/FingerprintEnroll',   name: 'TestFingerprintEnroll',          component: TestFingerprintEnroll        },
  { path: '/tests/FingerprintIdentification', name: 'TestIdentifyFingerprint',   component: TestIdentifyFingerprint      },
  { path: '/tests/ultimate',            name: 'TestUltimate',                   component: TestUltimate                 },
  { path: '/tests/ultimate_txs',        name: 'TestUltimateTransactions',       component: TestUltimateTransactions     },
  { path: '/tests/signature',           name: 'TestSignature',                  component: TestSignature                },
  { path: '/tests/dates',               name: 'TestDates',                      component: TestDates                    },

]

const router = new VueRouter({
  routes
})

export default router
