<template>
  <v-form v-model="valid" ref="form8">
    <v-container fluid class="text-left pb-3">
      <h4 class="mb-3">Thank You</h4>
      <v-checkbox
        v-model="formFields.weatherInsurance"
        label="Include weather insurance"
      ></v-checkbox>
      <p class="body2 text-left">
        Subject to loan approval, you have requested <Currency :value="k" /> in funding.
      </p>

      <p>Costs are estimated as follows:</p>

      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>Insurance</td>
              <td><Currency :value="Math.round(insurance)" /></td>
            </tr>
            <tr>
              <td>Fees</td>
              <td><Currency :value="Math.round(initiationFees)" /></td>
            </tr>
            <tr>
              <td>Interest</td>
              <td><Currency :value="Math.round(interest)" /></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <p class="mt-2">
        In total, you will repay: <Currency :value="Math.round(exposure)" />
      </p>

      <p>You acknowledge that all data provided is truthful and accurate.</p>

      <h4 class="mb-3">Please enter your OTP to acknowledge this</h4>
      <v-text-field
        v-model="formFields.otp"
        :label="$t('OTP')"
        :rules="[rules.required]"
        type="password"
      >
      </v-text-field>
      Complete terms and condition are available
      <a target="_blank" href="https://connect.sasa.solutions/OneFarmNileBreweriesFullContract.pdf">here</a>.
      <v-row class="mt-4">
        <v-btn color="success" class="mr-3 mb-2 d-xs-block" @click="validateAndSubmit" :disabled="true">Submit Application</v-btn><!-- Was !valid -->
        <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="validateAndPause" :disabled="!valid">Conclude Later</v-btn>
        <v-btn color="gray darken-3" class="mb-2" @click="previousStep"> Previous </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Currency from "@/components/Currency";

export default {
  name: "OtpAcknowledgement",

  components: { Currency },

  props: ["rules", "value", "next", "previous", "submit", "capital"],

  data: function () {
    return {
      valid: false,
      formFields: {},
      p: 0.025,    // weather insurance premium
      i: 0.0625,   // 12.5% pa, simple interest rate => 6.25% for 6 months
      f: 0.02,     // initiation fee
    };
  },

  computed: {
    k() { return (this.capital || 0); },
    exposure() { return this.k*(1 + this.f + this.i + this.i*this.f)/(1 - this.p - this.i*this.p); },
    insurance() {
      let r = 0;
      if (this.formFields.weatherInsurance) { r = this.exposure*this.p; }
      return r;
    },
    initiationFees() {
      return this.k * this.f;
    },
    interest() {
      return (this.k + this.k*this.f + this.exposure*this.p)*this.i;
    },


    farmersNeedsTotalInterest() { return (this.farmersNeedsTotal || 0)*0.125 },
    farmersNeedsTotalInterestAndWeatherInsurance() { return this.farmersNeedsTotalInterest + (this.farmersNeedsTotal || 0)*0.025; },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.formFields = to; },
    },
    formFields(to) { this.$emit("input", to); },
    valid(to) { this.$emit('valid', to); },
  },

  methods: {
    validate() {
      this.$refs.form8.validate();
    },
    validateAndSubmit() {

      this.validate();
      this.formFields.pricing = {
        pricing: {
          weatherInsurance: this.p,
          sixMonthInterestRate: this.i,
          initiationFees: this.f,
        },
        charges: {
          weatherInsurance: this.insurance,
          interest: this.interest,
          initiationFees: this.initiationFees,
          exposure: this.exposure,
        }
      }
      this.submit();
    },
    validateAndPause() {
      this.validate();
      this.$emit('nextStep');
    },
    previousStep() {
      this.$emit('previousStep');
    }
  },

};
</script>

<style>
</style>