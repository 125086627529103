import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

import { appState } from '@/store/stateWatcher';
import ImageDb from '@/store/ImageDb';
import {parseCustomerUpdatedAt} from "@/helpers/utils";
import {assessmentNameEnum} from "@/views/partners/hr_co/constant";
import {backendLookupUrl, getDomainInfo, remoteLookupFunc} from "@/mixins/errorLogging";

export default new Vuex.Store({
  state: {
    apiKey: null,
    authenticated: false,
    customersInitialised: false,

    networkOnline: true,

    search: '',
    onlineSearch: '',
    searchResults: [],

    cachedCustomerIdentifiers: [],
    retrieveDetailForCustomers: [],
    customers: {},
    allAlerts: [],

    trees: [],
    receipts: [],
    purchases: [],
    deliveries: [],
    orders: [],

    farmerActions: [],

    settings: null,

    geolocation: null,
    geolocationStatus: 'Not Set',

    images: [],
    documents: [],
    errorDetail: {},
    errorTrace: [],

    landingPageCache: undefined,
    hugoFormFields: {
      sheet5Phase1Section1: {},
      sheet5Phase1Section2: {},
      sheet5Phase1Section3: {}
    },
    customerAppTheme: null,
    assessments: [],
    milkCollections: [],
    recent_customer_search: [],

    businesses: {},
    actions: {},

    deploymentRelease: null,

    hasUltimateSafe: false,

  },

  mutations: {
    logout(state) {
      state.apiKey = null;
      state.authenticated = false;
      state.customersInitialised = false;

      state.search = '';
      state.onlineSearch = '';
      state.searchResults = [];
      state.recent_customer_search = [];

      state.cachedCustomerIdentifiers = [];
      state.retrieveDetailForCustomers = [];
      state.customers = {};

      state.trees = [];

      state.receipts = [];
      state.purchases = [];
      state.deliveries = [];
      state.orders = [];

      state.farmerActions = [];

      state.settings = null;
      
      state.images = [];

      state.businesses = {};
      state.actions = {};

      state.landingPageCache = undefined;
      state.assessments = [];
      state.hugoFormFields = [];
      state.hugoSheet5Phase1FormFields = [];
      state.hasUltimateSafe = false;
    },

    setAuthKey(state, key) { state.apiKey = key; },
    setUser(state, settings) { state.settings = settings; state.authenticated = true; },

    networkOnline(state, status) { state.networkOnline = status; },
    setCustomerAppTheme(state, customer) { state.customerAppTheme = customer; },

    updateSearch(state, search) { state.search = search; },
    updateOnlineSearch(state, search) { state.onlineSearch = search; },
    setSearchResults(state, results) { state.searchResults = results; },

    updateGeolocation(state, location) { state.geolocation = location; },
    setGeolocationStatus(state, status) { state.geolocationStatus = status; },

    setDefault(state, defaultSetting) { state.settings = { ...state.settings, ...defaultSetting }; },

    addImage(state, data) { state.images.push(data); },

    customersHaveBeenInitialised(state) { state.customersInitialised = true; },
    retrieveDetailForCustomers(state, customerUuids) { state.retrieveDetailForCustomers = customerUuids; },
    clearCustomerCache(state) { state.customers = {}; state.customersInitialised = false; },

    addCustomerDetailsToCache(state, details) {
      details.forEach( customer => {
        customer['demographic'] = customer['demographic'] || { identity: {} };
        customer['demographic']['identity'] = customer['demographic']['identity'] || {};
        customer['farm'] = customer['farm'] || {};
        Vue.set(state.customers, customer.uuid, customer);
        (customer.imageUrls || []).forEach( url => {
          const uuid = ImageDb.addUrlFromServer(url);
          state.images.push( { type: 'customer', type_id: customer.uuid, uuid: uuid } );
        });
        (customer.documentUrls || []).forEach( item => {
          if (item.contentType == 'application/pdf') {
            const uuid = ImageDb.addUrlFromServer(item.url);
            state.documents.push( { type: 'customer', type_id: customer.uuid, uuid: uuid, url:item.url, name:item.name } );
          }
        });
        (customer.fields || []).forEach( field => (field.imageUrls || []).forEach( url => {
          const uuid = ImageDb.addUrlFromServer(url);
          state.images.push( { type: 'field', type_id: field.uuid, uuid: uuid } );
        }));
        state.deliveries = state.deliveries.concat(customer.deliveries.filter( e => !state.deliveries.find( f => f.uuid==e.uuid ) ));
        state.purchases = state.purchases.concat(customer.purchases.filter( e => !state.purchases.find( f => f.uuid==e.uuid ) ));
        state.purchases = state.purchases.concat(customer.purchases);
      });
      state.customersInitialised = true;
    },
    addCustomersToCache(state, uuids) { state.cachedCustomerIdentifiers = state.cachedCustomerIdentifiers.concat(uuids); },

    upsertCustomer(state, customer) {
      // debugger
      customer.demographic = customer.demographic || {};
      Vue.set(state.customers, customer.uuid, customer);
      state.cachedCustomerIdentifiers.push(customer.uuid);
    },
    searchedCustomers(state, customer) {
      state.recent_customer_search = state.recent_customer_search.filter(obj => obj.uuid != customer.uuid);
      state.recent_customer_search.unshift(customer)
      state.recent_customer_search = state.recent_customer_search.slice(0,5)
    },
    upsertBusiness(state, business) {
      Vue.set(state.businesses, business.uuid, business);
    },
    upsertAction(state, action) {
      Vue.set(state.actions, action.uuid, action);
    },
    adjustRegions(state, regions) {
      if (regions.length>0) {
        let structure = state.settings.geographicStructure || {};
        if (regions[0]) {
          structure[regions[0]] = structure[regions[0]] || {};
          if (regions[1]) {
            structure[regions[0]][regions[1]] = structure[regions[0]][regions[1]] || [];
            if (regions[2]) {
              if ( !structure[regions[0]][regions[1]].includes(regions[2]) ) {
                structure[regions[0]][regions[1]].push(regions[2]);
              }
            }
          }
        }
      }
    },
    postCustomerUpsert(state, changes) {

      let customer = state.customers[changes.uuid];
      customer.id = changes.id;
      let n = 0;

      // for (const [temporaryId, id] of Object.entries(changes.newFieldIds || {})) {
      //   let field = customer.fields.filter(e => e.id==temporaryId)[0];
      //   field.id = id;
      // };

      // for (const [temporaryId, id] of Object.entries(changes.newAppointments || {})) {
      //   let appointment = customer.appointments.filter(e => e.id==temporaryId)[0];
      //   appointment.id = id;
      // };

      Vue.set(state.customers, customer.uuid, customer);
      
    },

    deleteCustomer(state, uuid) {
      Vue.delete(state.customers, uuid);
      const index = state.cachedCustomerIdentifiers.findIndex(e => e===uuid);
      Vue.delete(state.cachedCustomerIdentifiers, index);
    },

    addTree(state, tree) { state.trees.push(tree); },

    logErrorDetail(state, detail) {
      state.errorDetail = detail;
      state.errorTrace.push({ at: Date.now(), info: detail });
      },
    logErrorTrace(state, detail) {
      console.log(detail);
      state.errorTrace.push({ at: Date.now(), info: detail });
      if (state.errorTrace.length>15) { state.errorTrace.shift(); }
    },

    addReceipt(state, receipt) { state.receipts.push(receipt); },
    addPurchase(state, purchase) { state.purchases.push(purchase); },
    addDelivery(state, delivery) { state.deliveries.push(delivery); },
    deleteDelivery(state, uuid){
      const i = state.deliveries.findIndex(e=> e.uuid === uuid);
      if (i>=0) {
        let delivery =  state.deliveries[i];
        delivery.quantity = 0;
        state.deliveries[i] = delivery;
      }
    },
    addOrder(state, order) { state.orders.push(order); },
    flagOrderAsDeleted(state, uuid) {
      // don't want this to be communicated to the server as it being done there anyway.
      const i = state.orders.findIndex( e => e.uuid===uuid );
      if (i>=0) {
        let order = state.orders[i];
        order.status = 'deleted';
        state.orders[i] = order;
      }
    },
    deleteOrder(state, uuid) {
      // in contrast, these deletes must go to the server
      const i = state.orders.findIndex( e => e.uuid===uuid );
      if (i>=0) {
        let order = state.orders[i];
        order.status = 'deleted';
        state.orders[i] = order;
      }
    },

    addFarmerAction(state, farmerAction) {
      state.farmerActions.push(farmerAction);
    },

    updateSkus(state, skus) {
      state.settings.skus = skus;
    },

    updateLandingPageCache(state, detail) { state.landingPageCache = detail; },
    setAssessments(state, assessments) {
      state.assessments = assessments
    },
    newAssessment(state, assessment) {
      state.assessments.push(assessment)
    },
    newAssessments(state, assessments) {
      state.assessments = state.assessments.concat(assessments)
    },
    upsertAssessment(state, assessment) {
      state.assessments.push(assessment)
    },
    newAlert(state, alert) {
      state.allAlerts.push(alert)
    },
    removeAlert(state, alertIndex) {
      state.allAlerts.splice(alertIndex, 1)
    },
    milkCollectionUpsert(state, collectionPayload) {
      // console.log('milkCollectionUpsert', collectionPayload)
    },
    milkCollectionUpsertResponse(state, collectionPayload) {
      console.log('milkCollectionUpsertResponse', collectionPayload)
      state.milkCollections = [collectionPayload]
    },
    clearAlerts(state) {
      state.allAlerts = []
    },
    updateDeploymentRelease(state, deploymentRelease) {
      state.deploymentRelease = deploymentRelease
    },
    setUltimateSafe(state, has) {
      state.hasUltimateSafe = has;
    },
  },

  actions: {
    logout(context) {
      context.commit('logout');
      ImageDb.clear();
    },
    networkOnline(status) { context.commit('networkOnline', status); },

    pusherEvent({commit, state}, event) {
      console.log('pusher event');
      console.log(JSON.stringify(event));
      if (event.type=='Customer' || event.type=='Farmer' || event.type=='SavingsGroupMember' || event.type=='FieldAgent') {
        console.log('customer');
        const payload = event.payload;
        console.log(payload);
      }
      if (event.type=='field') {
        console.log('field');
        const payload = event.payload;
        console.log(payload);
      }
      if (event.type=='sku') {
        console.log('sku');
        const payload = event.payload;
        console.log(payload);
        let skus = state.settings.skus;
        const index = skus.findIndex(e => e.uuid==payload.uuid);
        console.log(index);
        if (index==-1) {
          skus.push(payload);
        } else {
          skus[index] = payload;
        }
        commit('updateSkus', skus);
      }
    },

    updateSearch(context, search) { context.commit('updateSearch', search); },
    updateOnlineSearch(context, search) { context.commit('updateOnlineSearch', search); },

    updateGeolocation(context) {
      context.commit('setGeolocationStatus', 'Updating');
      if ("geolocation" in navigator) {
        let options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        };
        navigator.geolocation.getCurrentPosition(pos => {
          context.commit('updateGeolocation', pos);
          context.commit('setGeolocationStatus', 'Set');
        }, err => {
          context.commit('updateGeolocation', null);
          context.commit('setGeolocationStatus', 'Unavailable');
        }, options);
      }
    },

    setDefault(context, defaultSetting) {
      context.commit('setDefault', defaultSetting);
    },

    addImage({commit, state}, data) {
      commit('addImage', data);
      data.apiKey = state.apiKey;
      data.partnerCode = state.settings.partnerCode;
      ImageDb.upload(data);
    },

    addCustomersToCache({commit, dispatch, state}, uuids) {
      let differences = uuids.filter(e => !state.cachedCustomerIdentifiers.includes(e));
      if (differences.length>0) {
        commit('addCustomersToCache', differences);
      }
      dispatch('confirmAllCustomersCached');
    },
    reloadAllCustomers({dispatch, commit}) {
      commit('clearCustomerCache');
      dispatch('confirmAllCustomersCached');
    },
    confirmAllCustomersCached({commit, state}) {
      const uuids = state.cachedCustomerIdentifiers;
      const downloaded = Object.keys(state.customers);
      let differences = uuids.filter(e => !downloaded.includes(e)).filter( e => e!=null );
      if (differences.length>0) {
        commit('retrieveDetailForCustomers', (differences.slice(0,20)));
      }
    },

    upsertCustomer(context, customer) {
      context.commit('upsertCustomer', customer);
      context.commit('adjustRegions', (customer.demographic || {}).geographicsArray || []);
    },
    searchedCustomers(context, customer) { context.commit('searchedCustomers', customer);},

    upsertBusiness(context, business) { context.commit('upsertBusiness', business); },
    upsertAction(context, action) { context.commit('upsertAction', action); },

    deleteCustomer(context, uuid) {
      context.commit('deleteCustomer', uuid);
    },
    upsertField(context, field) { context.commit('upsertField', field); },

    addTree(context, tree) { context.commit('addTree', tree); },

    logErrorDetail(context, detail) {
      const lookupUrl = backendLookupUrl()
      const apiKey = context.state.apiKey;
      const deploymentRelease = context.state.deploymentRelease
      const domainInfo = getDomainInfo()
      const params = {
        ...detail,
        appVersion: context.getters.appVersion,
        domain: domainInfo.domain,
        path: domainInfo.hash,
        deploymentRelease: deploymentRelease,
      }
      remoteLookupFunc(lookupUrl, 'logClientError', apiKey, params).then(r => {
      })
    },
    logErrorTrace(context, detail) { context.commit('logErrorTrace', detail) },

    addReceipt(context, receipt) { context.commit('addReceipt', receipt); },
    addPurchase(context, purchase) { context.commit('addPurchase', purchase); },
    addDelivery(context, delivery) { context.commit('addDelivery', delivery); },
    deleteDelivery(context, uuid) { context.commit('deleteDelivery', uuid);},
    addOrder({commit, state}, order) {
      commit('addOrder', order);
      state.orders.filter( e => (e.sku===order.sku && e.status==='placed' && !(e.uuid===order.uuid)) ).forEach( e => commit('flagOrderAsDeleted', e.uuid) );
    },
    deleteOrder(context, uuid) { context.commit('deleteOrder', uuid); },

    addFarmerAction(context, farmerAction) {
      context.commit('addFarmerAction', farmerAction);
    },

    updateLandingPageCache(context, detail) { context.commit('updateLandingPageCache', detail) },
    upsertAssessment(context, assessment) {
      context.commit('upsertAssessment', assessment);
    },
    newAlert(context, {message, type}) {
      context.commit('newAlert', {
        type: type,
        message: message
      });
    },
    removeAlert(context, alertIndex) {
      context.commit('removeAlert', alertIndex);
    },
    milkCollectionUpsert(context, collectionPayload) {
      context.commit('milkCollectionUpsert', collectionPayload);
    },
    clearAlerts(context) {
      context.commit('clearAlerts');
    },
    setHasUltimateSave(context,has) {
      context.commit('setHasUltimateSave',has);
    },
  },

  getters: {
    appVersion(state) { return (process.env.VUE_APP_VERSION || '0'); },
    apiKey(state) { return state.apiKey; },

    networkOnline(state) { return state.networkOnline; },
    
    authenticated(state) { return state.authenticated; },
    customersInitialised(state) { return state.customersInitialised; },

    search(state) { return state.search; },
    searchResults(state) { return state.searchResults; },

    unsyncedChanges(state) { return 0; },
    geolocation(state) { return state.geolocation; },
    geolocationStatus(state) { return state.geolocationStatus; },
    // settings(state) { return state.settings },
    partnerCode(state) { return state.settings.partnerCode; },
    countryCode(state) { return state.settings.countryCode; },
    cropNames(state) { return state.settings.crops.map( e => e[1]) },
    crops(state) { return state.settings.crops.map( e => { let r = {}; r.value = e[0]; r.text = e[1]; return r; } ) },
    cropLookups(state) { return state.settings.crops.reduce( function(map, e) { map[e[0]] = e[1]; return map; }, {} ) },

    currency(state) { return state.settings.currency },

    images(state) { return state.images; },
    documents(state) { return state.documents; },
    customersToCacheCount(state) { return state.cachedCustomerIdentifiers.length; },
    customersCachedCount(state) { return Object.keys(state.customers).length; },

    customers(state) {
      return Object.values(state.customers).map(customer => {
        return {...customer, assessments: state.assessments.filter(assessment => assessment.customerUuid === customer.uuid)}
      })
    },
    // not sure that the next five lines shouldn't move to the customers index object
    farmers(state, getters) {
      return getters.customers.filter( customer =>
          customer.assessments && customer.assessments.find(assessment => assessment.assessmentName === 'cultivationContract')
      );
    },
    contacts(state, getters) { return getters.customers.filter( customer => customer.hrContactStatus ); },
    hr_prospects(state, getters) {
      return getters.customers.filter( customer =>
          (!customer.assessments || !customer.assessments.find(assessment => assessment.assessmentName === 'cultivationContract'))
      );
    },
    agents(state, getters) { return getters.customers.filter( customer => customer.type==='FieldAgent' ) ; },
    uzima_ug_vas(state, getters) { return getters.customers.filter( customer => customer.isAnUzimaVillageAmbassador ); },
    uzima_ug_agents(state, getters) { return getters.customers.filter( customer => customer.isAnUzimaAgent ); },

    farmerActions: (state) => (partnerIdentifier) => {
      return state.farmerActions.filter( e => e.partnerIdentifier==partnerIdentifier );
    },

    customer: (state) => (partnerIdentifier) => {
      return state.customers[partnerIdentifier];
    },

    trees(state) { return state.trees; },

    receipt: (state) => (uuid) => { return state.receipts.filter(e => e.uuid===uuid)[0]; },
    receiptsForCustomer: (state) => (uuid) => { return state.receipts.filter(e => e.customerUuid===uuid); },

    purchase: (state) => (uuid) => { return state.purchases.filter(e => e.uuid===uuid)[0]; },
    purchasesForCustomer: (state) => (uuid) => { return state.purchases.filter(e => e.customerUuid===uuid); },

    delivery: (state) => (uuid) => { return state.deliveries.filter(e => e.uuid===uuid)[0]; },
    deliveriesForCustomer: (state) => (uuid) => { return state.deliveries.filter(e => e.customerUuid===uuid); },
    deliveriesForDeliveredTo: (state) => (uuid) => { return state.deliveries.filter(e => e.deliveredToUuid===uuid); },
    deliveries(state) { return state.deliveries; },

    order: (state) => (uuid) => { return state.orders.filter(e => e.uuid===uuid)[0]; },
    ordersForCustomer: (state) => (uuid) => { return state.orders.filter(e => ( (e.customerUuid===uuid) && (e.status!=='deleted') ) ); },
    orders: (state) => { return state.orders.filter(e => e.quantity > 0)},
    skus(state) { return state.settings.skus; },

    settings: (state) => { return (state.settings || {}); },

    errorTrace(state) { return state.errorTrace },
    errorDetail(state) { return state.errorDetail },

    landingPageCache(state) { return state.landingPageCache; },
    searchedCustomers(state) {return state.recent_customer_search},
    customerAppTheme(state) { return state.customerAppTheme; },

    assessments(state) {
      // todo remove usage
      return state.assessments;
    },
    customerAssessments: (state) => (customerUuid) => {
      return state.assessments.filter(assessment => assessment.customerUuid === customerUuid);
    },
    customerFirstVisit: (state) => (customerUuid) => {
      return state.assessments.find(e => (e.customerUuid===customerUuid && e.assessmentName === assessmentNameEnum.primeraVisita));
      },
    partnerCustomerSettings(state) {
      return ((((state.settings.settings || {}).app || {}).show || {}).customer || {});
    },
    customerUuids(state) {
      // debugger
      return Object.keys(state.customers);
    },
    activeCustomers(state) {
      return Object.values(state.customers).filter(customer => customer.deleted !== true);
    },
    customersUuidAndUpdatedAt(state, getters) {
      // todo create new object with customerUUid && latest update date
      // latest updated date should either come from customer, demographic, field, farm
      const results = parseCustomerUpdatedAt(getters.activeCustomers)
      return results
    },
    allAlerts(state) {
      return state.allAlerts;
    },
    hasUltimateSafe(state) {
      return state.hasUltimateSafe;
    },
    milkCollections(state) {
      return state.milkCollections
    },
  },

  modules: {},
  plugins: [ createPersistedState(), appState ],
  
});