<template>
  <v-container>
    <v-card>
      <v-card-title>
        Credit Details
        <v-spacer />
        <v-btn color="primary"  :to="{name: 'DeliveryDetails', query: { uuid: customerUuid } }" class="ml-1">Deliveries</v-btn>
      </v-card-title>

      <v-card-text>
        <v-simple-table v-if="balances" >
          <template v-slot:default>
            <tbody>
              <Row title="Balance">
                <ServerSideResult :test="balances">
                  <Currency :value="balances.total" />
                </ServerSideResult>
              </Row>
              <Row title="Interest">
                <ServerSideResult :test="balances">
                  <Currency :value="balances.interest" />
                </ServerSideResult>
              </Row>
              <Row title="Insurance">
                <ServerSideResult :test="balances">
                  <Currency :value="balances.insurance" />
                </ServerSideResult>
              </Row>
              <Row title="Deliveries">
                <ServerSideResult :test="balances">
                  <Currency :value="balances.inputs" />
                </ServerSideResult>
              </Row>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import Row from '@/components/Row';
  import ServerSideResult from '@/components/ServerSideResult';
  import Currency from '@/components/Currency';
  export default {
    components: { Row, ServerSideResult, Currency },
    name: 'CreditDetails',
    props: ['balances'],
    computed: {
      customerUuid() { return this.$route.query.uuid; },
    },
  }
</script>
